import React from 'react';
import b_ from 'b_';

import I18N from 'assets/i18n/root';

import Avito from './avito.svg';
import Beeline from './beeline.svg';
import Fsk from './fsk_2x.png';
import Hoff from './hoff_2x.png';
import Reckitt from './reckitt_2x.png';
import Rolf from './rolf.svg';
import Ugoria from './ugoria_2x.png';
import Vtb from './vtb.svg';

import './our-clients.css';

const b = b_.with('our-clients');

const i18n = I18N.ourClients;

const clientsLogos = [
    {img: Avito, alt: 'avito'},
    {img: Vtb, alt: 'vtb'},
    {img: Beeline, alt: 'beeline'},
    {img: Rolf, alt: 'rolf'},
    {img: Reckitt, alt: 'reckitt'},
    {img: Hoff, alt: 'hoff'},
    {img: Ugoria, alt: 'ugoria'},
    {img: Fsk, alt: 'fsk'}
];

const sliderItems = clientsLogos.map(logo => (
    <div className={b('slider-item')} key={logo.alt}>
        <img src={logo.img} alt={logo.alt} />
    </div>
));

export default function OurClients() {
    return (
        <div className={b()}>
            <div className={b('wrapper')}>
                <h1 className={b('header')}>{i18n.title}</h1>
                <div className={b('list')}>
                    <div className={b('slider')}>
                        {/* Отрисовываем лого дважды - для непрерывности ленты */}
                        {sliderItems}
                        {sliderItems}
                    </div>
                </div>
            </div>
        </div>
    );
}
