/* eslint-disable max-statements */
import 'whatwg-fetch';
import {logError} from '@yandex-int/pythia-libs/lib/rum';

import {handleErrorStatus} from 'client/common/utils/fetch-error-handler';
import cfg from 'client/common/utils/client-config';
import {Json} from 'client/common/types';

import type {ApiClientParams, MethodReturnValue} from './types';

const delay = (delayMS?: number) =>
    new Promise<void>(resolve => setTimeout(() => resolve(), delayMS));

type FetchResult =
    | {
          status: number;
      }
    | {
          data: any;
          reason: string;
          status: number;
      }
    | Error;

class BaseApiClient {
    private queue: Promise<any>;
    private host: string;
    private endpoint: string;
    private credentials: RequestCredentials;
    private headers: any;
    private csrfToken: string | null;

    constructor(props: ApiClientParams) {
        const {host, path, credentials, headers} = props;

        this.queue = Promise.resolve();
        this.host = host ?? location.host;
        this.endpoint = this._getEndpoint(path);

        this.credentials = credentials || 'include';
        this.headers = Object.assign(
            {
                'Content-Type': 'application/json',
                // для AJAX-каптчи, чтобы не редиректились fetch-и, а подставлялись данные каптчи в ответ
                'X-Requested-With': 'XMLHttpRequest',
                // чтобы после заполнения каптчи средиректило обратно на опрос
                'X-Retpath-Y': location.href
            },
            headers
        );
        this.csrfToken = null;
    }

    _getEndpoint = (path: string) => `https://${this.host}${path}`;

    // eslint-disable-next-line max-statements, complexity
    _doRequest = async (
        params: {
            method: string;
            retries?: number[];
            url: string;
            data?: any;
            file?: any;
            blob?: boolean;
            passQuery?: boolean;
            disableCSRF?: boolean;
        } & ({prefix: string} | {endpoint?: string})
    ): MethodReturnValue<any> => {
        const {url, data, method, file, passQuery = true, disableCSRF = false, blob} = params;

        let {retries} = params;

        let endpoint: string | undefined;

        if ('prefix' in params) {
            endpoint = this._getEndpoint(params.prefix);
        } else {
            endpoint = params.endpoint || this.endpoint;
        }

        const {credentials, headers} = this;

        const requestUrl = new URL(`${endpoint}${url}`);

        const locationParams = new URLSearchParams(location.search);

        if (passQuery && location.search) {
            locationParams.forEach((value, key) => {
                if (!key.startsWith('utm_')) {
                    requestUrl.searchParams.append(key, value);
                }
            });
        }

        const orgIdParam = locationParams.get('org');

        if (orgIdParam) {
            // переписываем если в урле уже был такой параметр
            requestUrl.searchParams.set('org', orgIdParam);
        }

        if (!retries) {
            retries = [];
        }

        const options: any = {
            method,
            credentials,
            headers
        };

        if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method) && data) {
            options.body = JSON.stringify(data);
        }

        options.headers['Content-Type'] = 'application/json';
        if (file) {
            delete options.headers['Content-Type'];
            options.body = file;
        }

        const fetchParams = {
            endpoint,
            disableCSRF,
            method,
            credentials,
            headers,
            blob,
            requestUrl,
            options,
            retries
        };

        // для get не нужна очередь, отправляем запросы параллельно
        if (method === 'GET') {
            return this.doFetch(fetchParams);
        }

        // Складываем запрос в очередь и продолжаем цепочку промисов, чтобы переживать флапы сети
        this.queue = this.queue.then(
            async () => this.doFetch(fetchParams),
            async () => this.doFetch(fetchParams)
        );

        return this.queue;
    };

    // Проверяем есть ли у нас непротухший csrf токен, если нет то устанавливаем его. Возвращается промис
    private async setCSRF({
        disableCSRF,
        method,
        headers,
        credentials
    }: {
        disableCSRF: boolean;
        method: string;
        headers: any;
        credentials: any;
    }) {
        // всегда ходим в /api/v0/csrf
        const endpoint = this._getEndpoint('/api/v0');

        const CSRF_URL = `${endpoint}/user/csrf`;

        // Для этих запросов csrf не нужен
        if (disableCSRF || ['GET', 'OPTIONS', 'HEAD'].includes(method)) {
            return Promise.resolve();
        }

        // Если есть токен, устанавливаем его в заголовки
        if (this.csrfToken) {
            headers['csrf-token'] = this.csrfToken;

            return Promise.resolve();
        }

        // Получаем csrf токен
        const res = await fetch(CSRF_URL, {
            method: 'get',
            headers,
            credentials
        });
        const {csrfToken} = await res.json();

        this.csrfToken = csrfToken;
        headers['csrf-token'] = csrfToken;

        return Promise.resolve();
    }

    private async doFetch(arg: {
        credentials: string;
        endpoint?: string;
        headers: any;
        method: string;
        options: any;
        requestUrl: URL;
        disableCSRF: boolean;
        retries?: number[];
        blob?: boolean;
    }): Promise<FetchResult> {
        const {disableCSRF, method, credentials, headers, requestUrl, options, blob, retries} = arg;

        await this.setCSRF({disableCSRF, method, credentials, headers});

        const res: Response = await fetch(requestUrl.toString(), options);

        try {
            if (blob) {
                return {data: await res.blob(), status: res.status};
            }

            let json;

            try {
                // пытаемся распарсить JSON из ответа, в случае ошибки отдаем только статус
                json = await res.json();
            } catch (error) {
                const {status} = res;

                handleErrorStatus(status);

                return {status};
            }

            const {status} = res;

            return this.handleJsonResponse(json, status);
        } catch (e: unknown) {
            // ретраим, если еще не закончились попытки
            if (retries && retries.length) {
                const retryDelayMs = retries.shift();

                return delay(retryDelayMs).then(() => this.doFetch(arg));
            }
            throw e;
        }
    }

    private handleInvalidCsrfResponse(json: Json, status: number): void {
        const reason = json && typeof json === 'object' && 'reason' in json && json.reason;

        // неверный csrf-токен
        if (status === 403 && reason && reason === 'csrf') {
            this.csrfToken = null;
            // выбрасываем ошибку, чтобы отправить ретрай за новым токеном
            throw new Error('Invalid csrf token');
        }
    }

    private handleCaptchaRewrite(json: Json): void {
        if (json && typeof json === 'object' && 'type' in json && json.type === 'captcha') {
            logError({
                err: new Error('Received captcha response'),
                additional: json,
                type: 'captcha'
            });

            location.href = (json.captcha as any)['captcha-page'];
        }
    }

    private handleJsonResponse(json: Json, status: number) {
        const reason = json && typeof json === 'object' && 'reason' in json && json.reason;

        this.handleInvalidCsrfResponse(json, status);
        this.handleCaptchaRewrite(json);

        return {data: json, reason, status};
    }
}

const baseApiClient = new BaseApiClient(cfg.api);

export const {_doRequest} = baseApiClient;
