export type Lang = 'ru' | 'en';

export interface UserAvatar {
    default: string;
    empty?: boolean;
}

export interface UserAccount {
    id: string;
    login: string;
    display_name: {
        avatar: UserAvatar;
        name: string;
    };
}

export interface UserInfo {
    authInfo: {
        isAgency: boolean;
        isAgencyClient: boolean;
    };
    avatar: UserAvatar;
    displayName?: string;
    email?: string;
    hasSurveys: boolean;
    isSuperreader: boolean;
    isSuperuser: boolean;
    isSupport: boolean;
    isDeveloper: boolean;
    isCreativeAssessor: boolean;
    isYandexPublisher: boolean;
    login?: string;
    settings: {[key: string]: any};
    uid: string;
    users: UserAccount[];
}

export interface UserProfile {
    email?: string;
    phone?: string;
    occupation?: string;
    receive_mailings?: boolean;
    intro_status?: 'FILLED' | 'SKIPPED';
}

export interface AgencyClient {
    id: string;
    name: string;
    packagesLeft: number;
}

export interface UserCountry {
    countryId?: number | null;
}

export const OrgRolePermissionList = [
    'read',
    'readStats',
    'readOrg',
    'createSurvey',
    'edit',
    'editCreatedBy',
    'manageUserAccess',
    'pay',
    'manage'
] as const;

export type OrgRolePermission = (typeof OrgRolePermissionList)[number];
export const orgRolePermissionSet = new Set(OrgRolePermissionList);
